#firstParag
{
  display: flex;
  justify-items: center;
  justify-content: center;
  animation: fadeIn 0.8s;
}

#firstParag p{
  margin: 20px 0;
}

#firstParag img{
  width: 50%;
  box-shadow: 0px 0px 20px 7px #00000036;
  margin-left: 50px;
  animation : fadeIn 1.2s;
  border-radius:15px;
}

#secondParag 
{
  display: flex;
  justify-content: center;
}

#thirdParag
{
  display: flex;
  justify-content: space-around;
}

#thirdParag > div
{
  width: 40%;
}

#thirdParag h3 , #forthParag h3
{
  text-align: center;
  margin-bottom: 15px;
}

#thirdParag li , #forthParag li{
    margin-bottom: 15px;
    padding: 10px;
    padding-left: 15px;
    background: white;
    box-shadow: 0px 0px 20px 10px #bdbdbd21;
    align-items: center;
    display: flex;
    animation: slideUp 1s;

}

#thirdParag li svg , #forthParag li svg
{width: 10%;
  font-size: 3rem;
  color: #f66216;
  margin-right: 15px;
}
.container h2
{
  border-left: 5px #f66216 solid;
  padding-left: 10px;
  animation: slideIn 1.2s;
  margin-bottom: 20px;
}

.container hr
{
  width: 70px;
  border: 3px solid #f66216;
  margin: 50px auto;
  border-radius: 10px;
}

.container .card 
{
  display: flex;
    border-radius: 25px;
    width: 33%;
    background: white;
    height: 30rem;
    margin: 0 15px;
    padding: 15px;
    box-shadow: 0px 0px 20px 10px #bdbdbd21;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* transition: all 0.8s ease-in-out; */
}
.container .card p
{
  text-align: center;
}

.card svg 
{
  font-size: 100px;
  color: #f66216;
}
#purpose
{
  animation: slideUp 1s;
}
#vision
{
  animation: slideUp 1.3s;
}
#mission
{
  animation: slideUp 1.5s;
}


@media (max-width:900px) {
  
    #firstParag  {
    flex-direction: column-reverse;
    }
    #firstParag img
    {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
    #secondParag 
    {
      flex-direction: column;
    }
    #secondParag .card
    {
      width: 100%;
      margin: 10px auto;
  }

  #thirdParag
  {
    flex-direction: column;
    align-items: center;
  }
  #thirdParag > div
  {
    width: auto;
  }
    }
  
  
  @media (max-width:600px){
    #firstParag img
    {
      display: none;
    }

    #secondParag svg , #thirdParag svg
    {
     display: none; 
    }

    #secondParag .card , #thirdParag .card
    {
     height: auto;
    }
  }