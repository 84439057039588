#projectsContainer,#REEFContainer
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    animation:fadeIn 1s;
}
.project
{
    align-items: flex-end;
    display: flex;
    width: 40%;
    height: 500px;
    background-color:transparent!important;
    background-blend-mode: luminosity !important;
    transition: all 0.7s ease-in-out;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 10px;
    min-width: 400px;
    animation:fadeIn 1s;
}

.project:hover {
    background-color: white !important;
    cursor: pointer;
}
.project:hover .Pcontent
{
    transform: translateY(35%); 
    height: 100%;
}
.Pcontent
{
    transition: all 0.5s  cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    justify-content: flex-start;
    background: white;
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
    transform: translateY(70%);
    height: 100%;
    color: #0a2d51;
   
}

.Pinfo
{
    display: flex;
    justify-content: space-between;
    color: #9CA3AF;
}

.Pstart,.Pend
{
    margin: 0 5px;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-width: 4ch;
    white-space: nowrap;
}

.Pclient
{
    padding-left: 5px;
    border-left: 5px solid #f66216;
}
.Pdate
{
    display: flex;
}
.Ptitle
{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    white-space: nowrap;
}
.Pbrief
{
    overflow: hidden;
    max-height:82px ;
}

.Pcontent .btn
{
    background-color: #0a2d51;

}
@media screen and (max-width:300px) {
    #pContainer
{display: block;}

#ProjectTypes 
{
    flex-wrap: nowrap!important;
}

#ProjectTypes .input-container .icon svg {
    display: none;
}
#ProjectTypes .input-container 
{
    width: auto!important;
    height: auto!important;
    margin: 5px!important;
}
#ProjectTypes .input-container .radio-tile 
{
    padding: 10px!important;
}

#ProjectTypes .input-container .radio-tile-label
{

    letter-spacing: normal!important;
    font-weight: unset!important;
    text-transform: none!important;
    font-size: 0.6rem!important;
}
.project
{
    width: auto;
    height: auto;
    min-width: 160px;
}

.Pcontent
{
    padding: 0 5px;
    transform: translateY(0);
}

.Pcontent .btn
{
    margin-bottom: 10px;
}
}

@media screen and (max-width:600px) {
  
    #pContainer
{display: block;}
#ProjectTypes 
{
    flex-wrap: nowrap!important;
}

#ProjectTypes .input-container .icon svg {
    display: none;
}
#ProjectTypes .input-container 
{
    width: auto!important;
    height: auto!important;
    margin: 5px!important;
}
#ProjectTypes .input-container .radio-tile 
{
    padding: 10px!important;
}

#ProjectTypes .input-container .radio-tile-label
{

    letter-spacing: normal!important;
    font-weight: unset!important;
    text-transform: none!important;
    font-size: 0.6rem!important;
}

.project
{
    width: auto;
    height: auto;
    min-width: 160px;
}

.Pcontent
{
    padding: 0 5px;
    transform: translateY(0);
}

.Pcontent .btn
{
    margin-bottom: 10px;
}
}


#ProjectTypes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #0a2d51;
    margin-bottom: 20px;
}
#ProjectTypes .input-container {
    position: relative;
    height: 7rem;
    width: 7rem;
    margin: 0.5rem;
}
#ProjectTypes .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}
#ProjectTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
}
#ProjectTypes .input-container .icon svg {
    fill: #0a2d51;
    width: 3rem;
    height: 3rem;
}
#ProjectTypes .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0a2d51;
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile {
    background-color: #0a2d51;
    border: 2px solid #0a2d51;
    color: white;
    transform: scale(1.1, 1.1);
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile .icon svg {
    fill: white;
    background-color: #0a2d51;
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: white;
    background-color: #0a2d51;
}