@import url('https://fonts.googleapis.com/css2?family=Genos:wght@700;800&display=swap');
#intro
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 95vh;
    padding-bottom: 5%;
    color: white;
}

#title
{
    font-size: 6em;
    letter-spacing: 25px;
    font-family: 'Genos', sans-serif;
    text-shadow: 6px 4px 10px #0000005e;
}

section 
{
    animation : fadeIn 1.2s;
}

#slogan
{
    margin-top: 10px;
    font-size: 2em;
    font-style: italic;
    letter-spacing: 3px;
    height: 50px;
    overflow: hidden;
}

#slogan ul
{
    padding-left: 8px;
text-shadow: 0 0 13px #0000005e;
    animation: change 10s infinite;
    transform:translate3d(0,0,0);
}

@keyframes change {
    0%, 12.66%, 100% {transform:translate3d(0,0,0);}
    16.66%, 29.32% {transform:translate3d(0,-22%,0);}
    33.32%,45.98% {transform:translate3d(0,-42%,0);}
    49.98%,62.64% {transform:translate3d(0,-62%,0);}
    66.64%,79.3% {transform:translate3d(0,-84%,0);}
    83.3%,95.96% {transform:translate3d(0,-84%,0);}
  }
  

#teamCount
{    margin: 5% 0;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: white;
}
#teamCount div 
{
    display: flex;
    flex-direction: column;
    padding: 1%;
    text-align: center;
    font-size: 1.7rem;
}
#teamCount div label
{font-weight: bold;}

#scrollDown
{
    background: white;
    color: #0a2d51;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50px;
    height: 50px;
    align-items: center;
    font-weight: bold;
    font-size: larger;
    margin-top: 40px;
}

#scrollDown svg
{
    transform: translateY(-70px);
    opacity: 0;
    animation: scrollDown 3s infinite ease-in-out;
}


@keyframes scrollDown {
    0% {transform: translateY(-50px);opacity: 0;}
    50%{transform: translateY(-5px);opacity: 1;}
    75%{transform: translateY(40px);opacity: 0.5;}
    90%{opacity: 0;transform: translateY(50px)}
    100%{opacity: 0;transform: translateY(-50px);}
}
.plus {
    color: #f66216;
    margin-left: .25rem;
    font-size: 2rem;
    font-weight: bolder;
    display: inline-block;
    transform: scale(0);
    animation-name: zoom, bounce;
    animation-duration: 250ms, 3s;
    animation-iteration-count: 1, infinite;
    animation-timing-function: linear,linear;
    animation-fill-mode: forwards,forwards;
  }
/* .timeline
{
     background:  linear-gradient(90deg, rgba(2,115,190,1) 0%, rgba(108,151,202,1) 35%, rgba(236,235,241,1) 100%); 
} */
.timeline ,#team
{
    
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#team
{
    background-color: #0a2d51;
}

*::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #f66216c2;
}
.timeline ul li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    z-index: 2;
    background: #f66216;
}
.timeline ul li div {
    position: relative;
    bottom: 0;
    width: 400px;
    padding: 20px;
    background: #fff;
    box-shadow: 4px 13px 30px 1px #50505033;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.timeline ul li div time {
    position: absolute;
    background: #f66216;
    width: 80px;
    height: 30px;
    top: -15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    color: white;
}

.timeline ul li div p {
    text-align: center;
}

.timeline ul li:nth-of-type(odd) > div {
    left: 45px;
}
.timeline ul li:nth-of-type(even) > div {
    left: -439px;
}
.timeline ul li div {
    
    transition: all 0.5s ease-in-out;
}

.timeline ul li.in-view div {
    transform: none;
    visibility: visible;
    opacity: 1;
}
@media screen and (max-width: 900px) {
    #intro
    {
        padding-top: 10%;
    }
    #title
    {
        font-size: 5em;
    letter-spacing: 24px;
    }

    .timeline ul li div {
        width: 250px;
        flex-direction: column;
   }
    .timeline ul li div div {
        width: 80%;
        margin: 10px;
   }
    .timeline ul li:nth-of-type(even) > div {
        left: -289px;
   }
}
@media screen and (max-width: 600px) {
    body {
        background: white;
   }
   #intro
   {
       padding-top: 2%;
   }
   #title
    {
        font-size: 3em;
    letter-spacing: 10px;
    }

    #slogan {
        text-align: center;
        width: 100%;
        font-size:  1.5em;
    }
    .timeline ul li {
            width: auto;
            background-color: transparent;
            }
    .timeline ul li div {
        width: calc(100vw - 91px);
   }
   .timeline ul li:nth-of-type(odd) > div  , .timeline ul li:nth-of-type(even) > div{
    left: 0px;
}

.timeline ul li::after {
    background-color: transparent;
}
#teamCount div 
{
    font-size: 1rem;
    
}
#teamCount div .plus 
{font-size: 1rem;
}
}

.sectionTitle
{
    background: #f66216;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 50px;
    color: white;
}

#partners
{
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: 20px;
}

#partnersSlider
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    transform: translate(-120%, 0px);
    animation: slider 30s linear infinite;
}
.partnerContainer
{
    float: left;
    overflow: hidden;
    max-width: 20%;
    margin: 10px;
}
.partnerContainer img
{
    display: block;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 3/2;
    mix-blend-mode: color-burn;
}

@keyframes slider {
    0% {
         transform: translate(-150%, 0px);
         opacity: 1;
    }
    100%
    {
        transform: translate(150%, 0px);
         opacity: 1;
    }
}
@keyframes zoom {
    0% { transform: scale(0); }
    80% { transform: scale(1.2); }
    100% { transform: scale(1); }
  }
  
  @keyframes bounce {
    12% { transform: translateY(-5px); }
    17% { transform: translateY(0); }
    22% { transform: translateY(-2px); }
    26% { transform: translateY(0); }
  }