#Error
{
    width: 100%;
    text-align: center;
    
    color: #0a2d51; 
}

#Error 
{

}

#Error svg
{
    font-size: xx-large;
}