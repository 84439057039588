
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #edf0f1; */
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar 
{
  display: none;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cairo , sans-serif;;
  list-style: none;
  text-decoration: none;
}

#root
{
  background-image: url(./assets/bg4.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* height: 100vh; */
  overflow: hidden;
}
/* arabic style */
.arb
{
  border-right: 5px #f66216 solid;
  border-left: 0px #f66216 solid !important;
  padding-left: 0px !important;
  padding-right: 10px;
  text-align: right;
}
.arbSub
{
  text-align: right;
  border-left: 0px solid rgb(10, 45, 81) !important;
  border-right: 5px solid rgb(10, 45, 81);
  margin-left: 0px !important;
  margin-right: 15px;
  padding-left: 0px !important;
  padding-right: 5px;
}
#reefImgArb
{
  width: 100px;
  top: 25px;
  position: absolute;
}
.aboutImg
{
  margin-left: 0px !important;
  margin-right: 50px;
}

/* end of arabic style */

.container
{
  background: white;
  margin: 5%;
  padding: 52px;
  box-shadow: 0px 0px 20px 7px #ffc78d17;
  border-radius: 8px;
  transition: all ease-in-out 0.3s;
  animation: fadeIn 0.8s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
opacity: 1;
  }
}

@keyframes slideIn {
  from {
    padding-left: 0px;
  }
  to {
    padding-left: 10px;
  }
}
@keyframes slideUp {
  from{
    margin-top: 15%;
  }
  to {
    margin-top: 0%;
  }
}

@media (max-width:1200px) {
  /*     to be added on click of navBtn
  #contactContainer , #homeContainer , #aboutContainer , .container
  {
      display: none;
  } */
  #homeContainer, .container
      {
          display: inline-block;
      }

      
  }