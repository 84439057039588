#REEFContainer
{
    flex-direction: column;

}
#REEFTypesContainer{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
}
#REEFTypes
{
    display: flex;
    justify-content: center;
    margin: 2rem;
    flex-wrap: wrap;
}
#REEFIntro
{
    margin: 2.5rem auto;

}
#REEFTypes .input-container {
    position: relative;
    height: 4rem;
    width: 10rem;
    margin: 0.5rem;
}
#REEFTypes .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}
#REEFTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
}

#REEFTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: transform 300ms ease;
}

#REEFTypes .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0a2d51;
}
#REEFTypes .input-container .radio-button:checked + .radio-tile {
    background-color: #0a2d51;
    border: 2px solid #0a2d51;
    color: white;
    transform: scale(1.1, 1.1);
}
#REEFTypes .input-container .radio-button:checked + .radio-tile .icon svg {
    fill: white;
    background-color: #0a2d51;
}
#REEFTypes .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: white;
    background-color: #0a2d51;
}

#REEFCardTypes,#REEFVisitesTypes
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    animation: fadeIn 1s;
}
#REEFVisitesTypes
{
    margin-top: 30px;
}
.REEFCard
{
flex-direction: column;
justify-content: flex-end;
display: flex;
width: 33%;
height: 300px;
transition: all 0.7s ease-in-out;
box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
margin: 10px;
min-width: 300px;
animation: fadeIn 1s;

}



.REEFContent
{
    margin: 15px;
    display: flex;
    justify-content: center;
    background: white;
    padding: 15px 30px;
    flex-direction: column;
    align-items: center;
    color: #0a2d51;
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 44%);
}
.REEFContent h4
{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    white-space: nowrap;
}

.ExplorBtn
{transition: all 0.3s ease-in-out;}

.ExplorBtn span
{
    margin-left: 2px;
    font-size: x-small;
    transition: margin 0.2s ease-in-out;
}

.ExplorBtn:hover 

{
    cursor: pointer;
    color: #f66216;
}

.ExplorBtn:hover span
{
    margin-left: 5px;
}

.slider 
{
    padding: 30px;
}

.slider img
{
    object-fit: contain;
    width: 100%;
    height: 400px;
}

@media screen and (min-width: 1400px) {
    .REEFContent h4
{
    
    max-width: 35ch;
}
}

#reefImg
{
    width: 100px;
    top: 25px;
    right: 25px;
    position: absolute;
}

@media screen and (max-width: 600px) {
    #REEFContainer
    {
        
        flex-wrap: nowrap;
    }
    #REEFContainer > h3 
    {
        margin-left: 0px!important;
    }

   
}