footer
{
    background: #0A2D51;
    width: 100%;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
}

#footer
{
    width: 70%;
    margin-bottom: 25px;
}

#footer img 
{
 width: auto;   
}
#footerSecondSection
{
    display: flex;
    justify-content: space-evenly;
}

#footerLogo ,#footerAbout ,#footerSocial
{
    max-width: 33%;
}

#footerLogo
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
footer h3
{
    letter-spacing: 3px;
}
#footerAbout ,#footerSocial 
{
    padding-top: 20px;
}

#footerAbout h3,#footerSocial h3
{
   text-align: center;
   
}

#footerAbout h3::after,#footerSocial h3::after
{
    content: '';
    display: block;
    margin: auto;
    margin-bottom:10px;
    height: 4px;
    width: 35%;
    background: #F66216;
    border-radius: 24px;

}
#footerAbout li a
{
    transition: all 0.3s ease-in-out;
}
#footerAbout li a:hover
{
    padding-left: 5px;
    cursor: pointer;
    color: #F66216  ;
}

#footerAbout ul li a 
{
    color: white;
}
#footerContact
{
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #ffb58f3d;
}

.footerContactSection
{
    display: flex;
    align-items: center;
    max-width: 33%;
}

.footerContactSection svg
{
    font-size: 3rem;
    color: #F66216;

}

#footerSocial a
{
    color: white;
}

#footerSocial h3{
    margin-bottom: 40px;}

#footerSocial svg 
{
    font-size: 2rem;
    margin: 5px;
}

#footerSocial svg:hover
{
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

#footerSocial #fb:hover
{
    color: #3b5998;
}

#footerSocial #linkedin:hover
{
    color: #0A66C2;
}

.contactData
{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.contactData a
{
    color: white;
}

#copyRights 
{
    width: 100%;
    background: #163556;
    text-align: center;
}

#copyRights a

{
    color :#f66216;
}

@media screen and (max-width: 992px)
{
   #footer
   {
    width: auto;
   }
}
@media screen and (max-width: 660px) 
{
     #footer
     {
        width: auto;
     }
     #footerContact
     {
        flex-direction: column;
     }
     .footerContactSection {
        display: flex;
        align-items: center;
        max-width: max-content;
        margin: 10px;
     }

     #footerLogo
     {
        display: none;
     }

     #footerAbout, #footerSocial {
         max-width: max-content;
    }
}