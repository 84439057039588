#vacancyContainer
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.exp
{
    width: 100%;
}
.expLvl
{
    border-radius: 5px;
    background: #0a2d51;
    color: white;
    padding: 2px 10px;
}
.expYears
{
    margin-left: 30px;
    letter-spacing: 3px;
    color: #9CA3AF;
}

.job
{
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
}

.jobType,.jobplace
{
    padding: 0 10px;
    
    /* float: right; */
    /* width: 100%; */
    align-self: end;
    border-radius: 15px;
    border: 2px solid #0a2d51;
    color: #0a2d51;
    font-weight: bold;
    font-size: 10px;
}

.Vacancy
{
    position: relative;
    padding: 20px;
    width: 20%;
    min-height: 150px;
    box-shadow: 0px 0px 20px 7px #f1eff3;
    margin: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 2px solid #0a2d51;
    min-width: 250px;
}

.vacancyTitle
{
    letter-spacing: 2px;
    margin-bottom: 20px;
    color: #0a2d51;
}

.ribbon
{
    justify-content: center;
    display: flex;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 70px;
    height: 70px;

    overflow: hidden;
    align-items: center;
}

.notificationCard
{   
    
    width: 60%;
    height: 30%;
    color: #0a2d51;
    margin: 10% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.notificationCard h6
{
    color: #9CA3AF;
    margin-top: 1%;
    letter-spacing: 2px;
    text-align: center;
}

#formDiv .btn
{
    margin: 0;
    background-color: #0a2d51;
    max-width: 300px;
}

.notificationCard svg
{
    font-size: 4em;
    margin: 5%;
    color: #4BB543;
}

.ribbon::before
{
    content: '';
    position: absolute;
    width: 140%;
    height: 20px;
    background-color: #0a2d51;
    transform: rotate(-45deg) translateY(-15px);
}

#careerIntro
{
    display: flex;
    justify-content:space-around;
    align-items: center;
}
#careerIntro ul
{
    margin-left: 50px;
    margin-top: 25px;
}
#SVG-Div {
    width: 50%;
}
#careerIntro ul li
{
    padding-left: 5px;
    border-left: 5px solid #0a2d51;
    margin-bottom: 15px;
}

.careerSection
{
    margin-bottom: 50px;
}
.careerSection > img
{
    max-width: 50%;
}
#careerForm
{
    overflow: hidden;
    display: flex;
    padding: 10px;
    align-items: center;
    box-shadow: 0px 0px 20px 7px #f1eff3;
    border-radius: 10px;
}

#formDiv
{
    width: 50%;
}
#formDiv form
{
    
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-left: 20px;
}

#formDiv form input , select
{
    max-width: 300px;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid gainsboro;
    outline: 0;
    transition: all 0.3s ease-in-out;
}
#formDiv form input:focus
{
    
    border: 1px solid #0a2d51;
}
#formDiv form label
{
    color: #0a2d51;
}


@media screen and (max-width: 1400px) {
    .careerSection > img
    {
        display: none;
    }
}

@media screen and (max-width: 960px) {
    .Vacancy
    {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .expYears
    {
        margin-left: 10px;

    }
    .jobType,.jobplace
    {
        padding: 0 5px;
    
    }
    .expLvl
    {
        display: block;
        text-align: center;
    }

    #careerIntro ul 
    {
        margin-left: 0px;
    }
    #careerForm  img
    {
        display: none;
    }
    #SVG-Div {
        width: auto;
    }
    #formDiv form input, select ,#formDiv .btn{
        max-width: none;
    }
    #formDiv form {
        margin-right: 20px;
    }
    #formDiv form input , select , #formDiv .btn  , #formDiv{
        width: 100%
    }
}