#newsContainer
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.newscard .wrapper {
    /* background: url(https://images.unsplash.com/photo-1496979551903-46e46589a88b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cda12b5…&auto=format&fit=crop&w=634&q=80) 20% 1%/cover no-repeat; */
    background-size: cover;
}
.newscard .wrapper {
    width: 100%;
    background-color: #fff;
    min-height: 540px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}

.newscard
{
    width: 40%;
    margin: 2%;
}
.newscard .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f66216;
    color: #fff;
    padding: 0.8em;
}

.newscard .data {
    position: absolute;
    top: 80%;
    width: 100%;
    transition: all 0.3s;
}
.newscard .data:hover
{
     top: 75%;
}
.newscard .date .day {
    font-weight: 700;
    font-size: 24px;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
}

.newscard .date span {
    display: block;
    text-align: center;
}
.newscard .date .month, .newscard .date .year {
    font-size: 12px;
}

.newscard .date .month {
    text-transform: uppercase;
}
.newscard .data .content {
    padding: 1em;
    position: relative;
    z-index: 1;
    transform: translateY(0);
}
.Newstitle{
color: #0a2d51;
overflow: hidden;
text-overflow: ellipsis;
max-width: 30ch;
white-space: nowrap;
cursor: pointer;
transition: color 0.4s ease-in-out;
}

.Newstitle:hover
{
    color: #f66216;
}
.newscard .content {
    background-color: #fff;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}

.newscard .data .content .author
{
    color: #0a2d51;
}

.newscard .data .content .author span

{
    color: #9CA3AF;
}


@media screen and (max-width: 960px) {
   #newsContainer{
    flex-wrap: wrap;
   }
   .newscard
   {
       width: 100%;
   }
    }
@media screen and (max-width: 1200px) {
       .container
       {
        display: block;
       }


    }