@import url(https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Genos:wght@700;800&display=swap);

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background: #edf0f1; */
  height: 100%;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
body::-webkit-scrollbar 
{
  display: none;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Cairo , sans-serif;;
  list-style: none;
  text-decoration: none;
}

#root
{
  background-image: url(/static/media/bg4.a6171ea2.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  /* height: 100vh; */
  overflow: hidden;
}
/* arabic style */
.arb
{
  border-right: 5px #f66216 solid;
  border-left: 0px #f66216 solid !important;
  padding-left: 0px !important;
  padding-right: 10px;
  text-align: right;
}
.arbSub
{
  text-align: right;
  border-left: 0px solid rgb(10, 45, 81) !important;
  border-right: 5px solid rgb(10, 45, 81);
  margin-left: 0px !important;
  margin-right: 15px;
  padding-left: 0px !important;
  padding-right: 5px;
}
#reefImgArb
{
  width: 100px;
  top: 25px;
  position: absolute;
}
.aboutImg
{
  margin-left: 0px !important;
  margin-right: 50px;
}

/* end of arabic style */

.container
{
  background: white;
  margin: 5%;
  padding: 52px;
  box-shadow: 0px 0px 20px 7px #ffc78d17;
  border-radius: 8px;
  transition: all ease-in-out 0.3s;
  -webkit-animation: fadeIn 0.8s;
          animation: fadeIn 0.8s;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
opacity: 1;
  }
}

@-webkit-keyframes slideIn {
  from {
    padding-left: 0px;
  }
  to {
    padding-left: 10px;
  }
}

@keyframes slideIn {
  from {
    padding-left: 0px;
  }
  to {
    padding-left: 10px;
  }
}
@-webkit-keyframes slideUp {
  from{
    margin-top: 15%;
  }
  to {
    margin-top: 0%;
  }
}
@keyframes slideUp {
  from{
    margin-top: 15%;
  }
  to {
    margin-top: 0%;
  }
}

@media (max-width:1200px) {
  /*     to be added on click of navBtn
  #contactContainer , #homeContainer , #aboutContainer , .container
  {
      display: none;
  } */
  #homeContainer, .container
      {
          display: inline-block;
      }

      
  }

#NavBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    flex-direction: row-reverse;
}

#NavBar ul
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
    transition: all 0.3s ease-in-out;
}

#NavBar ul a{
    z-index: 2;
    position: relative;
    color: white;
    transition: all 0.3s ease-in-out;
    font-size: larger;
}
#NavBar ul a:hover
{
    /* color: #faa61a */
}

.sun{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #faa61b ;
}

.windmill
{
    color: #0089d0;
}

/* sun color #faa61b , wind mill color #0089d0*/

#navBtn
{
    color: white;
    display: none;
    cursor: pointer;
    font-size: xx-large;
    transition: all 0.3s ease-in-out;
}

#logo a img {
    width: 70%;
}


#lang
{
    width: 70%;
    cursor: pointer;
}
@media (max-width:1200px) {
/*     to be added on click of navBtn
#contactContainer , #homeContainer , #aboutContainer , .container
{
    display: none;
} */
#homeContainer
    {
        display: inline-block;
    }
    #logo a img {
        width: 50%;
    }
#NavBar
{

width: 100%;
display: inline-block;
    }
#navBtn {
    display: block;
    padding: 0 1rem;
    float: right;
    font-size: 2.5em;
}

#logo {
    margin-left: 10px;
    float: left;
}

#lang
{
    width: auto;
}

#NavBar ul {
    top:75px;
    z-index: 9;
    position: fixed;
    width: 100%;
    float: left;
    display: block; /* to be block when navBtn clicked*/
    margin: 0px auto;
    text-align: center;
    height: 100vh;
    background-color: white;
    transition: all 0.3s ease-in-out;
}


#NavBar ul li 
{
    padding:15px 0;
}

#NavBar ul li:hover
{
    background-color: #0076c8;
}
#NavBar ul li:hover a
{
    color: #fff;
}

#NavBar ul li a
    {
        color: #0076c8;
    }
}

@media (max-width:600px){
    #NavBar
    {
        display: inline-block;
    }
    
    #check:checked ~ ul li {
        padding: 15px 0;
    }
    
}


/* #NavBar ul li a:before
{
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0px;
    bottom: 0px;
    content: '';
    background: #FFF;
    opacity: 0.3;
    transition: all 0.3s;
}

#NavBar ul li a:hover:before
{
    height: 100%;
} */

/* Circle behind */
#NavBar ul li a:before, #NavBar ul li a:after
{
    position: absolute;
    top: 19px;
    left: 50%;
    width: 30px;
    height: 30px;
    border: 4px solid #F66216;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(0.8);
            transform: translateX(-50%) translateY(-50%) scale(0.8);
    border-radius: 50%;
    background: transparent;
    content: "";
    opacity: 0;
    transition: all 0.3s;
    z-index: -1;
}

#NavBar ul li a:after
{
    border-width: 2px;
    transition: all 0.4s;
}

#NavBar ul li a:hover:before
{
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1);
            transform: translateX(-50%) translateY(-50%) scale(1);
}

#NavBar ul li a:hover:after
{
    opacity: 1;
    -webkit-transform: translateX(-50%) translateY(-50%) scale(1.3);
            transform: translateX(-50%) translateY(-50%) scale(1.3);
}
#intro
{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 95vh;
    padding-bottom: 5%;
    color: white;
}

#title
{
    font-size: 6em;
    letter-spacing: 25px;
    font-family: 'Genos', sans-serif;
    text-shadow: 6px 4px 10px #0000005e;
}

section 
{
    -webkit-animation : fadeIn 1.2s;
            animation : fadeIn 1.2s;
}

#slogan
{
    margin-top: 10px;
    font-size: 2em;
    font-style: italic;
    letter-spacing: 3px;
    height: 50px;
    overflow: hidden;
}

#slogan ul
{
    padding-left: 8px;
text-shadow: 0 0 13px #0000005e;
    -webkit-animation: change 10s infinite;
            animation: change 10s infinite;
    -webkit-transform:translate3d(0,0,0);
            transform:translate3d(0,0,0);
}

@-webkit-keyframes change {
    0%, 12.66%, 100% {-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);}
    16.66%, 29.32% {-webkit-transform:translate3d(0,-22%,0);transform:translate3d(0,-22%,0);}
    33.32%,45.98% {-webkit-transform:translate3d(0,-42%,0);transform:translate3d(0,-42%,0);}
    49.98%,62.64% {-webkit-transform:translate3d(0,-62%,0);transform:translate3d(0,-62%,0);}
    66.64%,79.3% {-webkit-transform:translate3d(0,-84%,0);transform:translate3d(0,-84%,0);}
    83.3%,95.96% {-webkit-transform:translate3d(0,-84%,0);transform:translate3d(0,-84%,0);}
  }

@keyframes change {
    0%, 12.66%, 100% {-webkit-transform:translate3d(0,0,0);transform:translate3d(0,0,0);}
    16.66%, 29.32% {-webkit-transform:translate3d(0,-22%,0);transform:translate3d(0,-22%,0);}
    33.32%,45.98% {-webkit-transform:translate3d(0,-42%,0);transform:translate3d(0,-42%,0);}
    49.98%,62.64% {-webkit-transform:translate3d(0,-62%,0);transform:translate3d(0,-62%,0);}
    66.64%,79.3% {-webkit-transform:translate3d(0,-84%,0);transform:translate3d(0,-84%,0);}
    83.3%,95.96% {-webkit-transform:translate3d(0,-84%,0);transform:translate3d(0,-84%,0);}
  }
  

#teamCount
{    margin: 5% 0;
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    color: white;
}
#teamCount div 
{
    display: flex;
    flex-direction: column;
    padding: 1%;
    text-align: center;
    font-size: 1.7rem;
}
#teamCount div label
{font-weight: bold;}

#scrollDown
{
    background: white;
    color: #0a2d51;
    padding: 10px;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 50px;
    height: 50px;
    align-items: center;
    font-weight: bold;
    font-size: larger;
    margin-top: 40px;
}

#scrollDown svg
{
    -webkit-transform: translateY(-70px);
            transform: translateY(-70px);
    opacity: 0;
    -webkit-animation: scrollDown 3s infinite ease-in-out;
            animation: scrollDown 3s infinite ease-in-out;
}


@-webkit-keyframes scrollDown {
    0% {-webkit-transform: translateY(-50px);transform: translateY(-50px);opacity: 0;}
    50%{-webkit-transform: translateY(-5px);transform: translateY(-5px);opacity: 1;}
    75%{-webkit-transform: translateY(40px);transform: translateY(40px);opacity: 0.5;}
    90%{opacity: 0;-webkit-transform: translateY(50px);transform: translateY(50px)}
    100%{opacity: 0;-webkit-transform: translateY(-50px);transform: translateY(-50px);}
}


@keyframes scrollDown {
    0% {-webkit-transform: translateY(-50px);transform: translateY(-50px);opacity: 0;}
    50%{-webkit-transform: translateY(-5px);transform: translateY(-5px);opacity: 1;}
    75%{-webkit-transform: translateY(40px);transform: translateY(40px);opacity: 0.5;}
    90%{opacity: 0;-webkit-transform: translateY(50px);transform: translateY(50px)}
    100%{opacity: 0;-webkit-transform: translateY(-50px);transform: translateY(-50px);}
}
.plus {
    color: #f66216;
    margin-left: .25rem;
    font-size: 2rem;
    font-weight: bolder;
    display: inline-block;
    -webkit-transform: scale(0);
            transform: scale(0);
    -webkit-animation-name: zoom, bounce;
            animation-name: zoom, bounce;
    -webkit-animation-duration: 250ms, 3s;
            animation-duration: 250ms, 3s;
    -webkit-animation-iteration-count: 1, infinite;
            animation-iteration-count: 1, infinite;
    -webkit-animation-timing-function: linear,linear;
            animation-timing-function: linear,linear;
    -webkit-animation-fill-mode: forwards,forwards;
            animation-fill-mode: forwards,forwards;
  }
/* .timeline
{
     background:  linear-gradient(90deg, rgba(2,115,190,1) 0%, rgba(108,151,202,1) 35%, rgba(236,235,241,1) 100%); 
} */
.timeline ,#team
{
    
    width: 100%;
    padding: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
#team
{
    background-color: #0a2d51;
}

*::before, *::after {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}


.timeline ul li {
    list-style-type: none;
    position: relative;
    width: 6px;
    margin: 0 auto;
    padding-top: 50px;
    background: #f66216c2;
}
.timeline ul li::after {
    content: '';
    position: absolute;
    left: 50%;
    bottom: 0;
    -webkit-transform: translateX(-50%) rotate(45deg);
            transform: translateX(-50%) rotate(45deg);
    width: 20px;
    height: 20px;
    z-index: 2;
    background: #f66216;
}
.timeline ul li div {
    position: relative;
    bottom: 0;
    width: 400px;
    padding: 20px;
    background: #fff;
    box-shadow: 4px 13px 30px 1px #50505033;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.timeline ul li div time {
    position: absolute;
    background: #f66216;
    width: 80px;
    height: 30px;
    top: -15px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    color: white;
}

.timeline ul li div p {
    text-align: center;
}

.timeline ul li:nth-of-type(odd) > div {
    left: 45px;
}
.timeline ul li:nth-of-type(even) > div {
    left: -439px;
}
.timeline ul li div {
    
    transition: all 0.5s ease-in-out;
}

.timeline ul li.in-view div {
    -webkit-transform: none;
            transform: none;
    visibility: visible;
    opacity: 1;
}
@media screen and (max-width: 900px) {
    #intro
    {
        padding-top: 10%;
    }
    #title
    {
        font-size: 5em;
    letter-spacing: 24px;
    }

    .timeline ul li div {
        width: 250px;
        flex-direction: column;
   }
    .timeline ul li div div {
        width: 80%;
        margin: 10px;
   }
    .timeline ul li:nth-of-type(even) > div {
        left: -289px;
   }
}
@media screen and (max-width: 600px) {
    body {
        background: white;
   }
   #intro
   {
       padding-top: 2%;
   }
   #title
    {
        font-size: 3em;
    letter-spacing: 10px;
    }

    #slogan {
        text-align: center;
        width: 100%;
        font-size:  1.5em;
    }
    .timeline ul li {
            width: auto;
            background-color: transparent;
            }
    .timeline ul li div {
        width: calc(100vw - 91px);
   }
   .timeline ul li:nth-of-type(odd) > div  , .timeline ul li:nth-of-type(even) > div{
    left: 0px;
}

.timeline ul li::after {
    background-color: transparent;
}
#teamCount div 
{
    font-size: 1rem;
    
}
#teamCount div .plus 
{font-size: 1rem;
}
}

.sectionTitle
{
    background: #f66216;
    height: 30px;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    font-weight: bold;
    padding: 10px;
    margin-bottom: 50px;
    color: white;
}

#partners
{
    background: white;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow: hidden;
    width: 100%;
    padding: 20px;
}

#partnersSlider
{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    position: relative;
    -webkit-transform: translate(-120%, 0px);
            transform: translate(-120%, 0px);
    -webkit-animation: slider 30s linear infinite;
            animation: slider 30s linear infinite;
}
.partnerContainer
{
    float: left;
    overflow: hidden;
    max-width: 20%;
    margin: 10px;
}
.partnerContainer img
{
    display: block;
    width: 100%;
    object-fit: contain;
    aspect-ratio: 3/2;
    mix-blend-mode: color-burn;
}

@-webkit-keyframes slider {
    0% {
         -webkit-transform: translate(-150%, 0px);
                 transform: translate(-150%, 0px);
         opacity: 1;
    }
    100%
    {
        -webkit-transform: translate(150%, 0px);
                transform: translate(150%, 0px);
         opacity: 1;
    }
}

@keyframes slider {
    0% {
         -webkit-transform: translate(-150%, 0px);
                 transform: translate(-150%, 0px);
         opacity: 1;
    }
    100%
    {
        -webkit-transform: translate(150%, 0px);
                transform: translate(150%, 0px);
         opacity: 1;
    }
}
@-webkit-keyframes zoom {
    0% { -webkit-transform: scale(0); transform: scale(0); }
    80% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    100% { -webkit-transform: scale(1); transform: scale(1); }
  }
@keyframes zoom {
    0% { -webkit-transform: scale(0); transform: scale(0); }
    80% { -webkit-transform: scale(1.2); transform: scale(1.2); }
    100% { -webkit-transform: scale(1); transform: scale(1); }
  }
  
  @-webkit-keyframes bounce {
    12% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
    17% { -webkit-transform: translateY(0); transform: translateY(0); }
    22% { -webkit-transform: translateY(-2px); transform: translateY(-2px); }
    26% { -webkit-transform: translateY(0); transform: translateY(0); }
  }
  
  @keyframes bounce {
    12% { -webkit-transform: translateY(-5px); transform: translateY(-5px); }
    17% { -webkit-transform: translateY(0); transform: translateY(0); }
    22% { -webkit-transform: translateY(-2px); transform: translateY(-2px); }
    26% { -webkit-transform: translateY(0); transform: translateY(0); }
  }
#loading
{
    width: 100%;
    text-align: center;
    font-size: x-large;
    color: #f66216;
}
#Error
{
    width: 100%;
    text-align: center;
    
    color: #0a2d51; 
}

#Error 
{

}

#Error svg
{
    font-size: xx-large;
}
#firstParag
{
  display: flex;
  justify-items: center;
  justify-content: center;
  -webkit-animation: fadeIn 0.8s;
          animation: fadeIn 0.8s;
}

#firstParag p{
  margin: 20px 0;
}

#firstParag img{
  width: 50%;
  box-shadow: 0px 0px 20px 7px #00000036;
  margin-left: 50px;
  -webkit-animation : fadeIn 1.2s;
          animation : fadeIn 1.2s;
  border-radius:15px;
}

#secondParag 
{
  display: flex;
  justify-content: center;
}

#thirdParag
{
  display: flex;
  justify-content: space-around;
}

#thirdParag > div
{
  width: 40%;
}

#thirdParag h3 , #forthParag h3
{
  text-align: center;
  margin-bottom: 15px;
}

#thirdParag li , #forthParag li{
    margin-bottom: 15px;
    padding: 10px;
    padding-left: 15px;
    background: white;
    box-shadow: 0px 0px 20px 10px #bdbdbd21;
    align-items: center;
    display: flex;
    -webkit-animation: slideUp 1s;
            animation: slideUp 1s;

}

#thirdParag li svg , #forthParag li svg
{width: 10%;
  font-size: 3rem;
  color: #f66216;
  margin-right: 15px;
}
.container h2
{
  border-left: 5px #f66216 solid;
  padding-left: 10px;
  -webkit-animation: slideIn 1.2s;
          animation: slideIn 1.2s;
  margin-bottom: 20px;
}

.container hr
{
  width: 70px;
  border: 3px solid #f66216;
  margin: 50px auto;
  border-radius: 10px;
}

.container .card 
{
  display: flex;
    border-radius: 25px;
    width: 33%;
    background: white;
    height: 30rem;
    margin: 0 15px;
    padding: 15px;
    box-shadow: 0px 0px 20px 10px #bdbdbd21;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    /* transition: all 0.8s ease-in-out; */
}
.container .card p
{
  text-align: center;
}

.card svg 
{
  font-size: 100px;
  color: #f66216;
}
#purpose
{
  -webkit-animation: slideUp 1s;
          animation: slideUp 1s;
}
#vision
{
  -webkit-animation: slideUp 1.3s;
          animation: slideUp 1.3s;
}
#mission
{
  -webkit-animation: slideUp 1.5s;
          animation: slideUp 1.5s;
}


@media (max-width:900px) {
  
    #firstParag  {
    flex-direction: column-reverse;
    }
    #firstParag img
    {
      width: 100%;
      margin: 0;
      margin-top: 10px;
    }
    #secondParag 
    {
      flex-direction: column;
    }
    #secondParag .card
    {
      width: 100%;
      margin: 10px auto;
  }

  #thirdParag
  {
    flex-direction: column;
    align-items: center;
  }
  #thirdParag > div
  {
    width: auto;
  }
    }
  
  
  @media (max-width:600px){
    #firstParag img
    {
      display: none;
    }

    #secondParag svg , #thirdParag svg
    {
     display: none; 
    }

    #secondParag .card , #thirdParag .card
    {
     height: auto;
    }
  }
#cardsContainer
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.cardServ{
    position: relative;
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    padding: 1rem;
    margin: 1rem;
    width: 45%;
    min-width: 350px;
    min-height: 350px;
    text-align: center;
    color: whitesmoke;
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    background-size: cover;
    background-color:rgb(122, 122, 122);
    box-shadow: 0 1px 1px rgb(0 0 0 / 10%), 0 2px 2px rgb(0 0 0 / 10%), 0 4px 4px rgb(0 0 0 / 10%), 0 8px 8px rgb(0 0 0 / 10%), 0 16px 16px rgb(0 0 0 / 10%);
}
.cardServ:hover
{
    cursor: pointer;
}
.cardServ:hover .content
{
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.cardServ:hover::before
{
    -webkit-transform: translateY(-4%);
            transform: translateY(-4%);
}
.cardServ:hover::after
{
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.content
{
    width: 100%;
    z-index: 1;
    transition : 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    -webkit-transform: translateY(80%);
            transform: translateY(80%);
}

.cardServ::after{
    transition : 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 200%;
    pointer-events: none;
    background-image: linear-gradient(
      to bottom,
      hsla(0, 0%, 0%, 0) 0%,
      hsla(0, 0%, 0%, 0.009) 11.7%,
      hsla(0, 0%, 0%, 0.034) 22.1%,
      hsla(0, 0%, 0%, 0.072) 31.2%,
      hsla(0, 0%, 0%, 0.123) 39.4%,
      hsla(0, 0%, 0%, 0.182) 46.6%,
      hsla(0, 0%, 0%, 0.249) 53.1%,
      hsla(0, 0%, 0%, 0.320) 58.9%,
      hsla(0, 0%, 0%, 0.394) 64.3%,
      hsla(0, 0%, 0%, 0.468) 69.3%,
      hsla(0, 0%, 0%, 0.540) 74.1%,
      hsla(0, 0%, 0%, 0.607) 78.8%,
      hsla(0, 0%, 0%, 0.668) 83.6%,
      hsla(0, 0%, 0%, 0.721) 88.7%,
      hsla(0, 0%, 0%, 0.762) 94.1%,
      hsla(0, 0%, 0%, 0.790) 100%
    );
}

.cardServ::before
{
    transition: 0.5s cubic-bezier(0.19, 1, 0.22, 1);
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 110%;
    background-size: cover;
    background-position: 0 0;
    pointer-events: none;
    
}

.cardServ:hover .copy
{
    opacity: 1;
}

.copy
{
    opacity: 0;
}
.btn
{
    cursor: pointer;
    margin-top: 1.5rem;
    padding: 0.75rem 1.5rem;
    font-size: 0.65rem;
    font-weight: bold;
    letter-spacing: 0.025rem;
    text-transform: uppercase;
    color: white;
    background-color: black;
    border: none;
}


.overlay {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 2;
    -webkit-animation: fadeIn 0.8s;
            animation: fadeIn 0.8s;
    transition:all 0.5s ease-in-out;
  }

.modalCard , .notificationCard
{   overflow: overlay;
    margin: 10% auto;
    width: 80%;
    background: white;
    padding: 20px;
    border-radius: 10px;
    height: 60%;
    position: relative;
}

.modalCard h3{
    border-left: 5px #f66216 solid;
    padding-left: 10px;
    -webkit-animation: slideIn 1.2s;
            animation: slideIn 1.2s;
    margin-bottom: 20px;
}

.modalCard button , .notificationCard button
{
    margin: 20px;
    position: absolute;
    right: 0;
    top: 0;
    padding: 5px;
    background: transparent;
    border: none;
    cursor: pointer;
    font-weight: bold;
    transition:all 0.2s ease-in-out;
    color: #aaa;
}

.modalCard button:hover ,.notificationCard button:hover
{
    color:#f66216
}
@media screen and (max-width: 960px) {
#cardsContainer
  {   
    align-items: center;
  }
  .cardServ{
    width: 100%;
  }
}
@media screen and (max-width: 600px) {
    .cardServ{
        min-width: auto;
    }
    .modalCard button , .notificationCard button
    {
        margin: 0px 10px;
    }
}
#projectsContainer,#REEFContainer
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    -webkit-animation:fadeIn 1s;
            animation:fadeIn 1s;
}
.project
{
    align-items: flex-end;
    display: flex;
    width: 40%;
    height: 500px;
    background-color:transparent!important;
    background-blend-mode: luminosity !important;
    transition: all 0.7s ease-in-out;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
    overflow: hidden;
    margin: 10px;
    min-width: 400px;
    -webkit-animation:fadeIn 1s;
            animation:fadeIn 1s;
}

.project:hover {
    background-color: white !important;
    cursor: pointer;
}
.project:hover .Pcontent
{
    -webkit-transform: translateY(35%);
            transform: translateY(35%); 
    height: 100%;
}
.Pcontent
{
    transition: all 0.5s  cubic-bezier(0.4, 0, 0.2, 1);
    display: flex;
    justify-content: flex-start;
    background: white;
    padding: 0 20px;
    width: 100%;
    flex-direction: column;
    -webkit-transform: translateY(70%);
            transform: translateY(70%);
    height: 100%;
    color: #0a2d51;
   
}

.Pinfo
{
    display: flex;
    justify-content: space-between;
    color: #9CA3AF;
}

.Pstart,.Pend
{
    margin: 0 5px;
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-width: 4ch;
    white-space: nowrap;
}

.Pclient
{
    padding-left: 5px;
    border-left: 5px solid #f66216;
}
.Pdate
{
    display: flex;
}
.Ptitle
{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 20ch;
    white-space: nowrap;
}
.Pbrief
{
    overflow: hidden;
    max-height:82px ;
}

.Pcontent .btn
{
    background-color: #0a2d51;

}
@media screen and (max-width:300px) {
    #pContainer
{display: block;}

#ProjectTypes 
{
    flex-wrap: nowrap!important;
}

#ProjectTypes .input-container .icon svg {
    display: none;
}
#ProjectTypes .input-container 
{
    width: auto!important;
    height: auto!important;
    margin: 5px!important;
}
#ProjectTypes .input-container .radio-tile 
{
    padding: 10px!important;
}

#ProjectTypes .input-container .radio-tile-label
{

    letter-spacing: normal!important;
    font-weight: unset!important;
    text-transform: none!important;
    font-size: 0.6rem!important;
}
.project
{
    width: auto;
    height: auto;
    min-width: 160px;
}

.Pcontent
{
    padding: 0 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.Pcontent .btn
{
    margin-bottom: 10px;
}
}

@media screen and (max-width:600px) {
  
    #pContainer
{display: block;}
#ProjectTypes 
{
    flex-wrap: nowrap!important;
}

#ProjectTypes .input-container .icon svg {
    display: none;
}
#ProjectTypes .input-container 
{
    width: auto!important;
    height: auto!important;
    margin: 5px!important;
}
#ProjectTypes .input-container .radio-tile 
{
    padding: 10px!important;
}

#ProjectTypes .input-container .radio-tile-label
{

    letter-spacing: normal!important;
    font-weight: unset!important;
    text-transform: none!important;
    font-size: 0.6rem!important;
}

.project
{
    width: auto;
    height: auto;
    min-width: 160px;
}

.Pcontent
{
    padding: 0 5px;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}

.Pcontent .btn
{
    margin-bottom: 10px;
}
}


#ProjectTypes {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    color: #0a2d51;
    margin-bottom: 20px;
}
#ProjectTypes .input-container {
    position: relative;
    height: 7rem;
    width: 7rem;
    margin: 0.5rem;
}
#ProjectTypes .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}
#ProjectTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
}
#ProjectTypes .input-container .icon svg {
    fill: #0a2d51;
    width: 3rem;
    height: 3rem;
}
#ProjectTypes .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0a2d51;
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile {
    background-color: #0a2d51;
    border: 2px solid #0a2d51;
    color: white;
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile .icon svg {
    fill: white;
    background-color: #0a2d51;
}
#ProjectTypes .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: white;
    background-color: #0a2d51;
}
#REEFContainer
{
    flex-direction: column;

}
#REEFTypesContainer{
    display: flex;
    margin-top: 30px;
    flex-direction: column;
}
#REEFTypes
{
    display: flex;
    justify-content: center;
    margin: 2rem;
    flex-wrap: wrap;
}
#REEFIntro
{
    margin: 2.5rem auto;

}
#REEFTypes .input-container {
    position: relative;
    height: 4rem;
    width: 10rem;
    margin: 0.5rem;
}
#REEFTypes .input-container .radio-button {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    margin: 0;
    cursor: pointer;
}
#REEFTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
}

#REEFTypes .input-container .radio-tile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border: 2px solid #0a2d51;
    border-radius: 5px;
    padding: 1rem;
    transition: -webkit-transform 300ms ease;
    transition: transform 300ms ease;
    transition: transform 300ms ease, -webkit-transform 300ms ease;
}

#REEFTypes .input-container .radio-tile-label {
    text-align: center;
    font-size: 0.75rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #0a2d51;
}
#REEFTypes .input-container .radio-button:checked + .radio-tile {
    background-color: #0a2d51;
    border: 2px solid #0a2d51;
    color: white;
    -webkit-transform: scale(1.1, 1.1);
            transform: scale(1.1, 1.1);
}
#REEFTypes .input-container .radio-button:checked + .radio-tile .icon svg {
    fill: white;
    background-color: #0a2d51;
}
#REEFTypes .input-container .radio-button:checked + .radio-tile .radio-tile-label {
    color: white;
    background-color: #0a2d51;
}

#REEFCardTypes,#REEFVisitesTypes
{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    -webkit-animation: fadeIn 1s;
            animation: fadeIn 1s;
}
#REEFVisitesTypes
{
    margin-top: 30px;
}
.REEFCard
{
flex-direction: column;
justify-content: flex-end;
display: flex;
width: 33%;
height: 300px;
transition: all 0.7s ease-in-out;
box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
margin: 10px;
min-width: 300px;
-webkit-animation: fadeIn 1s;
        animation: fadeIn 1s;

}



.REEFContent
{
    margin: 15px;
    display: flex;
    justify-content: center;
    background: white;
    padding: 15px 30px;
    flex-direction: column;
    align-items: center;
    color: #0a2d51;
    box-shadow: 0 0 20px 2px rgb(0 0 0 / 44%);
}
.REEFContent h4
{
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 30ch;
    white-space: nowrap;
}

.ExplorBtn
{transition: all 0.3s ease-in-out;}

.ExplorBtn span
{
    margin-left: 2px;
    font-size: x-small;
    transition: margin 0.2s ease-in-out;
}

.ExplorBtn:hover 

{
    cursor: pointer;
    color: #f66216;
}

.ExplorBtn:hover span
{
    margin-left: 5px;
}

.slider 
{
    padding: 30px;
}

.slider img
{
    object-fit: contain;
    width: 100%;
    height: 400px;
}

@media screen and (min-width: 1400px) {
    .REEFContent h4
{
    
    max-width: 35ch;
}
}

#reefImg
{
    width: 100px;
    top: 25px;
    right: 25px;
    position: absolute;
}

@media screen and (max-width: 600px) {
    #REEFContainer
    {
        
        flex-wrap: nowrap;
    }
    #REEFContainer > h3 
    {
        margin-left: 0px!important;
    }

   
}
#newsContainer
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.newscard .wrapper {
    /* background: url(https://images.unsplash.com/photo-1496979551903-46e46589a88b?ixlib=rb-0.3.5&ixid=eyJhcHBfaWQiOjEyMDd9&s=cda12b5…&auto=format&fit=crop&w=634&q=80) 20% 1%/cover no-repeat; */
    background-size: cover;
}
.newscard .wrapper {
    width: 100%;
    background-color: #fff;
    min-height: 540px;
    position: relative;
    overflow: hidden;
    box-shadow: 0 19px 38px rgba(0, 0, 0, 0.3), 0 15px 12px rgba(0, 0, 0, 0.2);
}

.newscard
{
    width: 40%;
    margin: 2%;
}
.newscard .date {
    position: absolute;
    top: 0;
    left: 0;
    background-color: #f66216;
    color: #fff;
    padding: 0.8em;
}

.newscard .data {
    position: absolute;
    top: 80%;
    width: 100%;
    transition: all 0.3s;
}
.newscard .data:hover
{
     top: 75%;
}
.newscard .date .day {
    font-weight: 700;
    font-size: 24px;
    text-shadow: 2px 3px 2px rgba(0, 0, 0, 0.18);
}

.newscard .date span {
    display: block;
    text-align: center;
}
.newscard .date .month, .newscard .date .year {
    font-size: 12px;
}

.newscard .date .month {
    text-transform: uppercase;
}
.newscard .data .content {
    padding: 1em;
    position: relative;
    z-index: 1;
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.Newstitle{
color: #0a2d51;
overflow: hidden;
text-overflow: ellipsis;
max-width: 30ch;
white-space: nowrap;
cursor: pointer;
transition: color 0.4s ease-in-out;
}

.Newstitle:hover
{
    color: #f66216;
}
.newscard .content {
    background-color: #fff;
    box-shadow: 0 5px 30px 10px rgba(0, 0, 0, 0.3);
}

.newscard .data .content .author
{
    color: #0a2d51;
}

.newscard .data .content .author span

{
    color: #9CA3AF;
}


@media screen and (max-width: 960px) {
   #newsContainer{
    flex-wrap: wrap;
   }
   .newscard
   {
       width: 100%;
   }
    }
@media screen and (max-width: 1200px) {
       .container
       {
        display: block;
       }


    }
#vacancyContainer
{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}
.exp
{
    width: 100%;
}
.expLvl
{
    border-radius: 5px;
    background: #0a2d51;
    color: white;
    padding: 2px 10px;
}
.expYears
{
    margin-left: 30px;
    letter-spacing: 3px;
    color: #9CA3AF;
}

.job
{
    width: 100%;
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
}

.jobType,.jobplace
{
    padding: 0 10px;
    
    /* float: right; */
    /* width: 100%; */
    align-self: end;
    border-radius: 15px;
    border: 2px solid #0a2d51;
    color: #0a2d51;
    font-weight: bold;
    font-size: 10px;
}

.Vacancy
{
    position: relative;
    padding: 20px;
    width: 20%;
    min-height: 150px;
    box-shadow: 0px 0px 20px 7px #f1eff3;
    margin: 2rem;
    border-radius: 10px;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-top: 2px solid #0a2d51;
    min-width: 250px;
}

.vacancyTitle
{
    letter-spacing: 2px;
    margin-bottom: 20px;
    color: #0a2d51;
}

.ribbon
{
    justify-content: center;
    display: flex;
    position: absolute;
    top: -5px;
    left: -5px;
    width: 70px;
    height: 70px;

    overflow: hidden;
    align-items: center;
}

.notificationCard
{   
    
    width: 60%;
    height: 30%;
    color: #0a2d51;
    margin: 10% auto;
    display: flex;
    align-items: center;
    flex-direction: column;
}

.notificationCard h6
{
    color: #9CA3AF;
    margin-top: 1%;
    letter-spacing: 2px;
    text-align: center;
}

#formDiv .btn
{
    margin: 0;
    background-color: #0a2d51;
    max-width: 300px;
}

.notificationCard svg
{
    font-size: 4em;
    margin: 5%;
    color: #4BB543;
}

.ribbon::before
{
    content: '';
    position: absolute;
    width: 140%;
    height: 20px;
    background-color: #0a2d51;
    -webkit-transform: rotate(-45deg) translateY(-15px);
            transform: rotate(-45deg) translateY(-15px);
}

#careerIntro
{
    display: flex;
    justify-content:space-around;
    align-items: center;
}
#careerIntro ul
{
    margin-left: 50px;
    margin-top: 25px;
}
#SVG-Div {
    width: 50%;
}
#careerIntro ul li
{
    padding-left: 5px;
    border-left: 5px solid #0a2d51;
    margin-bottom: 15px;
}

.careerSection
{
    margin-bottom: 50px;
}
.careerSection > img
{
    max-width: 50%;
}
#careerForm
{
    overflow: hidden;
    display: flex;
    padding: 10px;
    align-items: center;
    box-shadow: 0px 0px 20px 7px #f1eff3;
    border-radius: 10px;
}

#formDiv
{
    width: 50%;
}
#formDiv form
{
    
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    margin-left: 20px;
}

#formDiv form input , select
{
    max-width: 300px;
    margin-bottom: 15px;
    padding: 5px;
    border: 1px solid gainsboro;
    outline: 0;
    transition: all 0.3s ease-in-out;
}
#formDiv form input:focus
{
    
    border: 1px solid #0a2d51;
}
#formDiv form label
{
    color: #0a2d51;
}


@media screen and (max-width: 1400px) {
    .careerSection > img
    {
        display: none;
    }
}

@media screen and (max-width: 960px) {
    .Vacancy
    {
        width: 100%;
    }
}

@media screen and (max-width: 600px) {
    .expYears
    {
        margin-left: 10px;

    }
    .jobType,.jobplace
    {
        padding: 0 5px;
    
    }
    .expLvl
    {
        display: block;
        text-align: center;
    }

    #careerIntro ul 
    {
        margin-left: 0px;
    }
    #careerForm  img
    {
        display: none;
    }
    #SVG-Div {
        width: auto;
    }
    #formDiv form input, select ,#formDiv .btn{
        max-width: none;
    }
    #formDiv form {
        margin-right: 20px;
    }
    #formDiv form input , select , #formDiv .btn  , #formDiv{
        width: 100%
    }
}
#contactContainer
{
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: white;
}

#imgDiv
{
    -webkit-animation : fadeIn 1.2s;
            animation : fadeIn 1.2s;
}
#imgDiv , #cardsDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

#cardsDiv ,#cardsDiv a 
{
  color: #0a2d51;
}

#mobile
{
  -webkit-animation: slideUp 1s;
          animation: slideUp 1s;
}
#mail
{
  -webkit-animation: slideUp 1.3s;
          animation: slideUp 1.3s;
}
#location
{
  -webkit-animation: slideUp 1.5s;
          animation: slideUp 1.5s;
}
#imgDiv img
{
    width:50%;
   
}



@media screen and (max-width: 900px) {
  #cardsDiv
    {
      flex-direction: column;
    }
  #cardsDiv .card
    {
      width: 100%;
      height: auto;
      text-align: center;
      margin-bottom: 10px;
    }
    #cardsDiv .card svg
    {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    #imgDiv
    {
      display: none;
    }
    
    

    
  }
footer
{
    background: #0A2D51;
    width: 100%;
    display: flex;
    color: white;
    flex-direction: column;
    align-items: center;
}

#footer
{
    width: 70%;
    margin-bottom: 25px;
}

#footer img 
{
 width: auto;   
}
#footerSecondSection
{
    display: flex;
    justify-content: space-evenly;
}

#footerLogo ,#footerAbout ,#footerSocial
{
    max-width: 33%;
}

#footerLogo
{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}
footer h3
{
    letter-spacing: 3px;
}
#footerAbout ,#footerSocial 
{
    padding-top: 20px;
}

#footerAbout h3,#footerSocial h3
{
   text-align: center;
   
}

#footerAbout h3::after,#footerSocial h3::after
{
    content: '';
    display: block;
    margin: auto;
    margin-bottom:10px;
    height: 4px;
    width: 35%;
    background: #F66216;
    border-radius: 24px;

}
#footerAbout li a
{
    transition: all 0.3s ease-in-out;
}
#footerAbout li a:hover
{
    padding-left: 5px;
    cursor: pointer;
    color: #F66216  ;
}

#footerAbout ul li a 
{
    color: white;
}
#footerContact
{
    padding: 20px 0;
    display: flex;
    justify-content: space-evenly;
    border-bottom: 1px solid #ffb58f3d;
}

.footerContactSection
{
    display: flex;
    align-items: center;
    max-width: 33%;
}

.footerContactSection svg
{
    font-size: 3rem;
    color: #F66216;

}

#footerSocial a
{
    color: white;
}

#footerSocial h3{
    margin-bottom: 40px;}

#footerSocial svg 
{
    font-size: 2rem;
    margin: 5px;
}

#footerSocial svg:hover
{
    cursor: pointer;
    transition: all 0.3s ease-in-out;
}

#footerSocial #fb:hover
{
    color: #3b5998;
}

#footerSocial #linkedin:hover
{
    color: #0A66C2;
}

.contactData
{
    margin-left: 15px;
    display: flex;
    flex-direction: column;
}

.contactData a
{
    color: white;
}

#copyRights 
{
    width: 100%;
    background: #163556;
    text-align: center;
}

#copyRights a

{
    color :#f66216;
}

@media screen and (max-width: 992px)
{
   #footer
   {
    width: auto;
   }
}
@media screen and (max-width: 660px) 
{
     #footer
     {
        width: auto;
     }
     #footerContact
     {
        flex-direction: column;
     }
     .footerContactSection {
        display: flex;
        align-items: center;
        max-width: -webkit-max-content;
        max-width: max-content;
        margin: 10px;
     }

     #footerLogo
     {
        display: none;
     }

     #footerAbout, #footerSocial {
         max-width: -webkit-max-content;
         max-width: max-content;
    }
}
