#NavBar{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 0.3s ease-in-out;
    flex-direction: row-reverse;
}

#NavBar ul
{
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 70%;
    transition: all 0.3s ease-in-out;
}

#NavBar ul a{
    z-index: 2;
    position: relative;
    color: white;
    transition: all 0.3s ease-in-out;
    font-size: larger;
}
#NavBar ul a:hover
{
    /* color: #faa61a */
}

.sun{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background-color: #faa61b ;
}

.windmill
{
    color: #0089d0;
}

/* sun color #faa61b , wind mill color #0089d0*/

#navBtn
{
    color: white;
    display: none;
    cursor: pointer;
    font-size: xx-large;
    transition: all 0.3s ease-in-out;
}

#logo a img {
    width: 70%;
}


#lang
{
    width: 70%;
    cursor: pointer;
}
@media (max-width:1200px) {
/*     to be added on click of navBtn
#contactContainer , #homeContainer , #aboutContainer , .container
{
    display: none;
} */
#homeContainer
    {
        display: inline-block;
    }
    #logo a img {
        width: 50%;
    }
#NavBar
{

width: 100%;
display: inline-block;
    }
#navBtn {
    display: block;
    padding: 0 1rem;
    float: right;
    font-size: 2.5em;
}

#logo {
    margin-left: 10px;
    float: left;
}

#lang
{
    width: auto;
}

#NavBar ul {
    top:75px;
    z-index: 9;
    position: fixed;
    width: 100%;
    float: left;
    display: block; /* to be block when navBtn clicked*/
    margin: 0px auto;
    text-align: center;
    height: 100vh;
    background-color: white;
    transition: all 0.3s ease-in-out;
}


#NavBar ul li 
{
    padding:15px 0;
}

#NavBar ul li:hover
{
    background-color: #0076c8;
}
#NavBar ul li:hover a
{
    color: #fff;
}

#NavBar ul li a
    {
        color: #0076c8;
    }
}

@media (max-width:600px){
    #NavBar
    {
        display: inline-block;
    }
    
    #check:checked ~ ul li {
        padding: 15px 0;
    }
    
}


/* #NavBar ul li a:before
{
    position: absolute;
    width: 100%;
    height: 2px;
    left: 0px;
    bottom: 0px;
    content: '';
    background: #FFF;
    opacity: 0.3;
    transition: all 0.3s;
}

#NavBar ul li a:hover:before
{
    height: 100%;
} */

/* Circle behind */
#NavBar ul li a:before, #NavBar ul li a:after
{
    position: absolute;
    top: 19px;
    left: 50%;
    width: 30px;
    height: 30px;
    border: 4px solid #F66216;
    transform: translateX(-50%) translateY(-50%) scale(0.8);
    border-radius: 50%;
    background: transparent;
    content: "";
    opacity: 0;
    transition: all 0.3s;
    z-index: -1;
}

#NavBar ul li a:after
{
    border-width: 2px;
    transition: all 0.4s;
}

#NavBar ul li a:hover:before
{
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1);
}

#NavBar ul li a:hover:after
{
    opacity: 1;
    transform: translateX(-50%) translateY(-50%) scale(1.3);
}