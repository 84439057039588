#contactContainer
{
    margin-left: 0;
    margin-right: 0;
    border-radius: 0;
    background-color: white;
}

#imgDiv
{
    animation : fadeIn 1.2s;
}
#imgDiv , #cardsDiv
{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 2em;
}

#cardsDiv ,#cardsDiv a 
{
  color: #0a2d51;
}

#mobile
{
  animation: slideUp 1s;
}
#mail
{
  animation: slideUp 1.3s;
}
#location
{
  animation: slideUp 1.5s;
}
#imgDiv img
{
    width:50%;
   
}



@media screen and (max-width: 900px) {
  #cardsDiv
    {
      flex-direction: column;
    }
  #cardsDiv .card
    {
      width: 100%;
      height: auto;
      text-align: center;
      margin-bottom: 10px;
    }
    #cardsDiv .card svg
    {
      font-size: 50px;
    }
  }
  @media screen and (max-width: 600px) {
    #imgDiv
    {
      display: none;
    }
    
    

    
  }